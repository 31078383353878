import * as yup from 'yup'

export const addPageDataToProjectReportValidation = yup.object().shape({
  pageData: yup.string().required(),
})
export type addPageDataToProjectReportValidationType = yup.InferType<
  typeof addPageDataToProjectReportValidation
>

export const movePageInProjectReportValidation = yup.object().shape({
  index: yup.number().min(0).required(),
})
export type movePageInProjectReportValidationType = yup.InferType<
  typeof movePageInProjectReportValidation
>

export const sendReportEmail = yup.object().shape({
  emails: yup
    .array()
    .required()
    .of(yup.string().email().lowercase().required()),
})
export type sendReportEmailType = yup.InferType<typeof sendReportEmail>
