import { SerializedNode, SerializedNodes } from '@craftjs/core'
import {
  getContainerStyles,
  getLineStyles,
  getPageStyles,
  getTextStyles,
  getVideoStyles,
  getAudioStyles,
  getImageStyles,
  getFileStyles,
} from './ProjectReportsStyles'
import { Fragment } from 'react'
import ReactPlayer from 'react-player'
import { Image } from '@chakra-ui/react'

export const renderNode = (
  node: SerializedNode,
  data: SerializedNodes,
): React.ReactNode => {
  // Special handling for the root "Page" node
  if (node.displayName === 'Page') {
    return (
      <div style={getPageStyles(node.props)}>
        {node.nodes.map((nodeId, index) => (
          <Fragment key={nodeId || index}>
            {renderNode(data[nodeId], data)}
          </Fragment>
        ))}
      </div>
    )
  }
  const resolvedName =
    typeof node.type === 'object' ? node.type.resolvedName : node.type

  switch (resolvedName) {
    case 'Container':
      return (
        <div style={getContainerStyles(node.props)}>
          {node.nodes.map((nodeId, index) => (
            <Fragment key={nodeId || index}>
              {renderNode(data[nodeId], data)}
            </Fragment>
          ))}
        </div>
      )
    case 'Text':
      return (
        <div
          style={getTextStyles(node.props)}
          dangerouslySetInnerHTML={{ __html: node.props.text }}
        />
      )
    case 'Line':
      return <div style={getLineStyles(node.props)} />
    case 'Video':
      return (
        <div style={getVideoStyles(node.props)}>
          <ReactPlayer
            url={node.props.videoUrl}
            controls={true} // Basic playback controls
            width="100%"
            height="100%"
          />
        </div>
      )
    case 'Audio':
      return (
        <div style={getAudioStyles(node.props)}>
          <ReactPlayer
            url={node.props.audioUrl}
            controls={true} // Basic playback controls
            width="100%"
            height="100%"
          />
        </div>
      )
    case 'Image':
      return (
        <Image
          src={node.props.thumbnailUrl}
          alt={node.props.altText}
          style={getImageStyles(node.props)}
        />
      )
    case 'File':
      return (
        <div style={getFileStyles(node.props)}>
          {node.props.fileName} ({node.props.fileType})
        </div>
      )
    default:
      console.warn(`No rendering logic for node type ${resolvedName}`)
      return null
  }
}
