import { ErrorMessage } from './ErrorMessage'
import styled from '@emotion/styled'
import { Button } from './Button'
import { H1, P } from './Typography'
import { FallbackProps } from 'react-error-boundary'

const ErrorWrapper = styled.div`
  background-color: #ffcccc;
  border-radius: 3px;
`

const ErrorPre = styled.pre`
  white-space: pre-wrap; // Preserve line breaks and whitespace
  font-family: monospace; // Use a monospace font for better error formatting
`

const isQA = (process.env.COOKIE_DOMAIN ?? '').indexOf('.qa.') > -1
const isProd = process.env.NODE_ENV === 'production'

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <ErrorWrapper>
      <section>
        <H1 marginBottom="40px">Something went wrong:</H1>
        <P>Error Message:</P>
        <ErrorMessage marginBottom="16px">
          {error?.message ?? 'Error'}
        </ErrorMessage>
        {(!isProd || isQA) && (
          <>
            <P>
              Error Name: <strong>{error.name}</strong>
            </P>
            <P>
              File Name: <strong>{error.fileName || error.filename}</strong>
            </P>
            <P>
              Line: <strong>{error.lineNumber}</strong>, Column:{' '}
              <strong>{error.columnNumber}</strong>
            </P>
            <P>Stack:</P>
            <ErrorPre>{error.stack}</ErrorPre>
            {error.cause && (
              <>
                <P>Underlying Error:</P>
                <ErrorPre>{error.cause.stack}</ErrorPre>
              </>
            )}
          </>
        )}
        <Button type="button" onClick={resetErrorBoundary}>
          Try again
        </Button>
      </section>
    </ErrorWrapper>
  )
}
